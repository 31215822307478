@import "@radix-ui/themes/styles.css";
/* website redesign overrides, should be integrated into main theme config later */
.radix-themes {
    --cursor-button: pointer;
    --cursor-checkbox: pointer;
    --cursor-disabled: not-allowed;
    --cursor-link: pointer;
    --cursor-menu-item: pointer;
    --cursor-radio: pointer;
    --cursor-slider-thumb: pointer;
    --cursor-slider-thumb-active: pointer;
    --cursor-switch: pointer;

    /*--mint-9: var(--colorV2-green);*/
    /*--mint-a9: var(--colorV2-green);*/


    --mint-1: #fafefc;
    --mint-2: #f3fbf8;
    --mint-3: #e0f8f0;
    --mint-4: #cdf3e7;
    --mint-5: #b9ebdb;
    --mint-6: #a2dfcc;
    --mint-7: #84cfb9;
    --mint-8: #54baa0;
    --mint-9: #76d8bd;
    --mint-10: #6dcdb3;
    --mint-11: #007a63;
    --mint-12: #1b4137;

    --mint-a1: #00cc6605;
    --mint-a2: #00aa6b0c;
    --mint-a3: #00c6841f;
    --mint-a4: #00c28532;
    --mint-a5: #00b77c46;
    --mint-a6: #00a8745d;
    --mint-a7: #019c6e7b;
    --mint-a8: #009872ab;
    --mint-a9: #00b78589;
    --mint-a10: #00a87b92;
    --mint-a11: #007a63;
    --mint-a12: #002b1fe4;

    --mint-contrast: #082820;
    --mint-surface: #f0faf6cc;
    --mint-indicator: #76d8bd;
    --mint-track: #76d8bd;

    @supports (color: color(display-p3 1 1 1)) {
        @media (color-gamut: p3) {
            --mint-1: oklch(0.993 0.005 173.8);
            --mint-2: oklch(0.982 0.01 173.8);
            --mint-3: oklch(0.96 0.027 173.8);
            --mint-4: oklch(0.933 0.043 173.8);
            --mint-5: oklch(0.9 0.056 173.8);
            --mint-6: oklch(0.857 0.067 173.8);
            --mint-7: oklch(0.798 0.081 173.8);
            --mint-8: oklch(0.722 0.103 173.8);
            --mint-9: oklch(0.815 0.101 173.8);
            --mint-10: oklch(0.782 0.1 173.8);
            --mint-11: oklch(0.516 0.1 173.8);
            --mint-12: oklch(0.344 0.047 173.8);

            --mint-a1: color(display-p3 0.024 0.757 0.267 / 0.016);
            --mint-a2: color(display-p3 0.02 0.647 0.376 / 0.044);
            --mint-a3: color(display-p3 0.004 0.694 0.424 / 0.102);
            --mint-a4: color(display-p3 0.004 0.702 0.443 / 0.169);
            --mint-a5: color(display-p3 0.004 0.655 0.42 / 0.236);
            --mint-a6: color(display-p3 0.004 0.576 0.376 / 0.314);
            --mint-a7: color(display-p3 0.004 0.529 0.353 / 0.416);
            --mint-a8: color(display-p3 0 0.51 0.349 / 0.565);
            --mint-a9: color(display-p3 0.004 0.639 0.427 / 0.444);
            --mint-a10: color(display-p3 0.004 0.576 0.388 / 0.479);
            --mint-a11: color(display-p3 0 0.333 0.235 / 0.797);
            --mint-a12: color(display-p3 0 0.125 0.082 / 0.855);

            --mint-contrast: #082820;
            --mint-surface: color(display-p3 0.945 0.98 0.973 / 0.8);
            --mint-indicator: oklch(0.815 0.101 173.8);
            --mint-track: oklch(0.815 0.101 173.8);
        }
    }

    /* START TYPOGRAPHY STYLES */

    --default-font-family: var(--general-sans-font), Helvetica, sans-serif  !important;

    /* Display */
    --font-size-9: calc(60px * var(--scaling));
    --heading-line-height-9: calc(78px * var(--scaling));

    /* Headline XL */
    --font-size-8: calc(38px * var(--scaling));
    --heading-line-height-8: calc(46px * var(--scaling));

    /* Headline L */
    --font-size-7: calc(28px * var(--scaling));
    --heading-line-height-7: calc(34px * var(--scaling));

    /* Headline M */
    --font-size-6: calc(24px * var(--scaling));
    --heading-line-height-6: calc(34px * var(--scaling));

    /* Headline S */
    --font-size-5: calc(20px * var(--scaling));
    --heading-line-height-5: calc(28px * var(--scaling));

    /* M Headline M */
    --font-size-4: calc(18px * var(--scaling));
    --line-height-4: calc(27px * var(--scaling));

    /* Body M (Headline S Mobile) */
    --font-size-3: calc(16px * var(--scaling));
    --line-height-3: calc(25px * var(--scaling));
    --letter-spacing-3: 0.16px;

    /* Body S (Body M mobile) */
    --font-size-2: calc(14px * var(--scaling));
    --line-height-2: calc(20px * var(--scaling));
    --letter-spacing-2: 0.5px;

    /* Label XS : THIS FONT IS PROBABLY WRONG, LETS CIRCLE BACK HERE */
    --font-size-1: calc(11px * var(--scaling));
    --line-height-1: calc(15.4px * var(--scaling));
    --letter-spacing-1: 0.77px;
    /* END TYPOGRAPHY STYLES */

    --container-1: 700px;
    --container-2: 900px;
    --container-3: 1130px;
    --container-4: 1320px;
}
